export interface Project {
  id: string;
  name: string;
}
export interface Image {
  id: string;
  status: string;
  bucket: string;
  path: string;
  url?: string;
  observationId: string;
}

export interface Issue {
  type: string;
  severity: number;
  summary: string | null;
  workOrder: string | null;
  projectId: string;
  id: string;
  creationDate: string;
  modifiedDate: string;
  status: string;
  images: Array<Image>;
}

export interface UpdateIssue {
  id: string;
  type: string;
  severity: number;
  workOrder: string | null;
  summary: string | null;
}

export interface UpdateIssuesStatus {
  ids: Array<string>;
  value: string; // status value.
}

export const observationTypes = [
  'Connector Failure Temperature',
  'Soiling',
  'Connector Assembly',
  'Wire Management',
  'Connector Disconnection',
  'Cracked Module',
  'Cracked Module with Overheating',
  'Tracker Inspection',
  'Tracker Inspection Noncompliant',
  'Module Abnormality',
  'Ground wire',
  'Burn Mark',
  'Tracker Motor',
  'Connector Differential Temperature',
  'Broken Mount',
  'Debris',
  'Delamination',
  'J box Failure',
  'Torque Nut',
  'Fuse Assembly',
  'Fuse Inspection',
  'T Branch Differential',
  'GSU Inspection',
  'Conduit Failure',
  'T Branch Failure Temperature',
  'Junction box Disconnection',
  'Row inspection',
  'Vegetation Encroachment',
  'J Box Temperature Differential',
  'Bypass Diode',
  'Cell Differential',
  'Tracker out of alignment',
  'Shattered Module',
  'Tracker Alignment',
  'Production Loss',
  'BHA Hardware Malfunction',
  'Failed Dampers',
  'Incomplete Work',
  'Wireharness Shrinkback',
  'Pile Foundation',
  'Fuse Differential Temperature',
  'Broken Tracker Mounts',
  'Combiner Box Inspection',
  'BLA Temperature Differential',
  'String Disconnection',
  'Wildlife Documentation',
  'Racking Assembly',
  'Junction Box Assembly',
  'Hot Spot',
  'Weather Damage',
  'Observation',
  'Erosion',
  'Safety Hazard',
  'Document',
  'End of Row',
].sort();
