import { useEffect, useState, useContext } from 'react';
import '../App.css';
import AuthHeader from '../components/site-header';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Footer from '../components/footer';
import { Api } from '../services/api';
import LeftAdminNav from '../components/left-admin-nav';
import { SessionContext } from '../context/session-context';
import { useNavigate } from 'react-router-dom';

function AdminDashboard() {
  const sessionContext = useContext(SessionContext);
  const api = Api.getInstance();
  const history = useNavigate();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [hasProjects, setHasProjects] = useState<boolean>(false);
  const [projects, setProjects] = useState<Array<{ id: string; name: string }>>([]);

  function hasAccessToAdmin() {
    const email = sessionContext.details.email;
    return (email !== undefined && email.endsWith('onsightops.com')) || sessionContext.details.isAdmin === 'true';
  }

  async function getProjects() {
    if (!hasProjects) {
      const projects = await api.getProjects();
      setProjects(projects);
      setHasProjects(true);
    }
  }

  function onProjectChange(projectId: string) {
    history(`/admin/observations/${projectId}`);
  }

  useEffect(() => {
    getProjects();
    setIsAdmin(hasAccessToAdmin());
    // getIssues();
  });

  return (
    <>
      {isAdmin !== true && (
        <Container as="main" className="App py-4 px-3 mx-auto">
          <AuthHeader />
          <Container>
            <h1>Oops!</h1>
            <p>Sorry, an unexpected error has occurred.</p>
          </Container>
          <Footer />
        </Container>
      )}
      {isAdmin === true && (
        <Container as="main" className="App py-4 px-3 mx-auto" fluid>
          <AuthHeader />
          <Row>
            <Col lg={2}>
              <LeftAdminNav projects={projects} onChange={onProjectChange} />
            </Col>
            <Col lg={10}>
              <h2>Admin Dashboard</h2>
            </Col>
          </Row>
          <Footer />
        </Container>
      )}
    </>
  );
}

export default AdminDashboard;
