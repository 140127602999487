import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../App.css';
import AuthHeader from '../components/site-header';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Footer from '../components/footer';
import CamerasView from '../components/cameras-view';
import CameraLoader from '../components/camera/camera-loader';
import { Camera } from '../types/camera';
import { Site } from '../types/site';
import { Device } from '../types/device';
import LeftNav from '../components/left-nav';
import { Api } from '../services/api';
import { DeviceContext } from '../context/device-context';

function LiveView() {
  const api = Api.getInstance();
  const [devices, setDevices] = useState<Array<Device> | null>(null);
  const { robot } = useParams();
  const deviceContext = useContext(DeviceContext);
  const [cameras, setCameras] = useState<Array<Camera> | null>(() => {
    const siteString = window.sessionStorage.getItem('site');
    if (siteString !== null) {
      const site = JSON.parse(siteString) as Site;
      let cameras: Array<Camera> = [];
      const keys = Object.keys(site.devices);
      const devices: Array<Device> = [];
      keys.forEach((id) => {
        devices.push(site.devices[id]);
        if (id === robot) {
          const deviceCameras = site.devices[id].cameras;
          if (deviceCameras !== undefined) {
            cameras = cameras.concat(deviceCameras);
          }
        }
      });
      devices.sort((a, b) => (a.deviceName?.toLowerCase() > b.deviceName?.toLowerCase() ? 1 : -1));
      setDevices(devices);
      return cameras;
    }
    return null;
  });

  const getCameraFrameApi = async (camera: Camera) => {
    if (camera.url === undefined) return; // skip cameras that don't have a url.
    try {
      const cameraFrame = await api.getCameraFrame(camera);
      const updatedCameras = cameras?.map((camera) => {
        if (camera.name === cameraFrame.name && camera.device === cameraFrame.device) {
          return {
            name: cameraFrame.name,
            id: cameraFrame.id,
            url: cameraFrame.url,
            captured: cameraFrame.captured,
            device: cameraFrame.device,
            deviceName: cameraFrame.deviceName,
          };
        } else {
          return camera;
        }
      });
      if (updatedCameras !== undefined) {
        setCameras(updatedCameras);
        const deviceState = deviceContext.getDevice(cameraFrame.device);
        if (deviceState !== undefined) {
          deviceState.cameras = updatedCameras?.filter((camera) => camera.device === cameraFrame.device);
        }
      }
    } catch (error) {
      console.error('Error occurred fetching camera frame', error);
    }
  };

  const setup = async () => {
    try {
      const cameras: Array<Camera> = [];
      const devices: Array<Device> = deviceContext.devices;
      devices.forEach((device) => {
        if (device.cameras !== undefined && device.name === robot) {
          cameras.push(...device.cameras);
        }
      });
      setCameras(cameras);
      setDevices(devices);
    } catch (error) {
      console.error('Error occurred getting site data', error);
    }
  };

  useEffect(() => {
    setup();
    const interval = setInterval(() => {
      cameras?.forEach((camera) => {
        getCameraFrameApi(camera);
      });
    }, 60000);

    //Clearing the interval
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceContext, devices]);

  return (
    <>
      <Container as="main" className="App py-4 px-3 mx-auto" fluid>
        <AuthHeader />
        <Row>
          <Col lg={2}>{devices !== null && <LeftNav devices={devices} />}</Col>
          <Col lg={10}>
            {cameras === null && <CameraLoader></CameraLoader>}
            {cameras !== null && cameras.length > 0 && <CamerasView cameras={cameras}></CamerasView>}
            {cameras?.length === 0 && <p>No Cameras for this location.</p>}
          </Col>
        </Row>

        <Footer />
      </Container>
    </>
  );
}

export default LiveView;
