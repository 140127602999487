import Button from 'react-bootstrap/Button';
import Modal, { ModalProps } from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { Image } from '../../types/issue';
import IssueConfirmDialog from './issue-confirm-dialog';
import { useState } from 'react';

export interface IssueImageViewProps extends ModalProps {
  image?: Image;
  onDelete: () => void;
}

function IssueImageView(props: IssueImageViewProps) {
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const modelProps: ModalProps = { ...props };
  delete modelProps.image;
  delete modelProps.onDelete;
  function onConfirm() {
    console.log('Props', props.image);
    setShowConfirmDialog(false);
    props.onDelete();
  }
  return (
    <>
      <Modal {...modelProps} fullscreen={true}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="text-center">
          {props.image?.url === undefined && (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          )}
          {props.image?.url !== undefined && <img src={props.image?.url} alt={props.image?.path} id={props.image?.id} />}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setShowConfirmDialog(true)}>
            Delete
          </Button>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
      <IssueConfirmDialog
        show={showConfirmDialog}
        title="Confirm Deletion"
        message="Are you sure you want to delete this image."
        confirm={onConfirm}
        onHide={() => setShowConfirmDialog(false)}
      />
    </>
  );
}

export default IssueImageView;
