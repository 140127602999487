import Button from 'react-bootstrap/Button';
import Modal, { ModalProps } from 'react-bootstrap/Modal';

export interface ErrorDialogProps extends ModalProps {
  message: string;
  title: string;
}

function ErrorDialog(props: ErrorDialogProps) {
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{props.message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={props.onHide}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ErrorDialog;
