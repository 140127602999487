import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal, { ModalProps } from 'react-bootstrap/Modal';
import { Issue, observationTypes } from '../../types/issue';
import { ChangeEvent, useState } from 'react';

export interface IssueEditFormProps extends ModalProps {
  issue: Issue;
  onDelete: () => void;
  onUpdate: (issue: Issue) => void;
}

function IssueEditForm(props: IssueEditFormProps) {
  const [issue, setIssue] = useState(props.issue);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIssue({
      ...issue,
      [event.target.name]: event.target.value,
    });
  };
  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setIssue({
      ...issue,
      [event.target.name]: event.target.value,
    });
  };
  function onShow() {
    setIssue(props.issue);
  }
  const modelProps: ModalProps = { ...props };
  delete modelProps.issue;
  delete modelProps.onDelete;
  delete modelProps.onUpdate;
  return (
    <Modal {...modelProps} size="lg" aria-labelledby="contained-modal-title-vcenter" centered onShow={onShow}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Edit Observation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Id</Form.Label>
            <Form.Control type="text" name="id" readOnly={true} value={issue?.id} onChange={handleChange}></Form.Control>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Created Date</Form.Label>
            <Form.Control type="text" readOnly={true} name="creationDate" value={issue?.creationDate} onChange={handleChange}></Form.Control>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Type</Form.Label>
            <Form.Select name="type" value={issue?.type} onChange={handleSelectChange}>
              {observationTypes.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Severity</Form.Label>
            <Form.Select name="severity" value={issue?.severity} onChange={handleSelectChange}>
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Summary</Form.Label>
            <Form.Control name="summary" as="textarea" rows={3} value={issue?.summary === null ? '' : issue?.summary} onChange={handleChange}></Form.Control>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Work Order</Form.Label>
            <Form.Control
              name="workOrder"
              as="textarea"
              rows={3}
              value={issue?.workOrder === null ? '' : issue?.workOrder}
              onChange={handleChange}
            ></Form.Control>
          </Form.Group>
        </Form>
        <p>Observation Link</p>
        <a href={`/admin/observations/${props.issue.projectId}/id/${props.issue.id}`}>{props.issue.id}</a>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => props.onUpdate(issue)}>
          Update
        </Button>
        <Button variant="danger" onClick={props.onDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default IssueEditForm;
