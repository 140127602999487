import { ChangeEvent, useEffect, useRef, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../App.css';
import AuthHeader from '../components/site-header';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Footer from '../components/footer';
import { Api } from '../services/api';
import Table from 'react-bootstrap/Table';
import LeftAdminNav from '../components/left-admin-nav';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { Issue, Image, UpdateIssue, UpdateIssuesStatus, Project } from '../types/issue';
import IssueEditForm from '../components/observation/issue-edit-form';
import IssueImageView from '../components/observation/issue-image-view';
import 'rsuite/dist/rsuite.min.css';
import IssueConfirmDialog from '../components/observation/issue-confirm-dialog';
import ErrorDialog from '../components/error-dialog';
import IssuesEditDialog from '../components/observation/issues-edit-dialog';
import { SessionContext } from '../context/session-context';
import { useHotkeys } from 'react-hotkeys-hook';

function Observation() {
  useHotkeys('ctrl+r', () => refresh());
  const sessionContext = useContext(SessionContext);
  const api = Api.getInstance();
  const { projectId, observationId } = useParams();
  const history = useNavigate();
  const checkboxRef = useRef<HTMLInputElement>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [loadedObservationId, setLoadedObservationId] = useState<string>('');
  const [hasProjects, setHasProjects] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [editIssue, setEditIssue] = useState<Issue>({
    id: '',
    projectId: '',
    type: '',
    severity: 0,
    summary: '',
    workOrder: '',
    creationDate: '',
    modifiedDate: '',
    status: '',
    images: [],
  });
  const [showImageModal, setShowImageModal] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<Image | undefined>();
  const [showDeleteItemConfirmation, setShowDeleteItemConfirmation] = useState<boolean>(false);
  const [projects, setProjects] = useState<Array<Project>>([]);
  const [project, setProject] = useState<{ id: string; name: string } | undefined>();
  const [data, setData] = useState<Array<Issue> | null>(null);
  const [selected, setSelected] = useState<Map<string, Issue>>(new Map());
  const [error, setError] = useState<string | null>(null);
  const [showError, setShowError] = useState<boolean>(false);
  const [showIssuesEditDialog, setShowIssuesEditDialog] = useState<boolean>(false);
  const [showConfirmIssuesDialog, setShowConfirmIssuesDialog] = useState<boolean>(false);
  const [confirmInfo, setConfirmInfo] = useState<{ message: string; title: string }>({ message: '', title: '' });
  const [selectedOperation, setSelectedOperation] = useState<'approved' | 'unapproved' | 'deleted'>('approved');

  function hasAccessToAdmin() {
    const email = sessionContext.details.email;
    return (email !== undefined && email.endsWith('onsightops.com')) || sessionContext.details.isAdmin === 'true';
  }

  async function getProjects() {
    if (!hasProjects) {
      const projects = (await api.getProjects()) as Array<Project>;
      setProjects(projects);
      setProject(projects.find((p) => p.id === projectId));
      setHasProjects(true);
    }
  }

  async function refresh() {
    if (observationId === undefined) return;
    console.log('Refreshing');
    setData(await api.getObservation(observationId));
  }

  function onProjectChange(projectId: string) {
    history(`/admin/observations/${projectId}`);
    setProject(projects.find((p) => p.id === projectId));
  }

  function handleChecked(e: ChangeEvent<HTMLInputElement>, issue: Issue) {
    if (!e.target.checked) {
      setSelected((prev) => {
        const map = new Map(prev);
        map.delete(issue.id);
        return map;
      });
    } else {
      setSelected((prev) => {
        const map = new Map(prev);
        map.set(issue.id, issue);
        return map;
      });
    }
  }

  function handleAllChecked(e: ChangeEvent<HTMLInputElement>) {
    const map = new Map<string, Issue>();
    if (!isChecked()) {
      data?.forEach((issue) => map.set(issue.id, issue));
    }
    setSelected(map);
  }

  function isChecked(): boolean {
    const isChecked = selected.size > 0 && selected.size === (data?.length ?? 0);
    if (checkboxRef.current !== null && selected.size > 0 && !isChecked) {
      checkboxRef.current.indeterminate = true;
    } else if (checkboxRef.current !== null) {
      checkboxRef.current.indeterminate = false;
    }
    return isChecked;
  }

  function isButtonsDisabled(): boolean {
    return selected.size === 0;
  }

  function doEditIssue(issue: Issue) {
    setEditIssue(issue);
    setShowEditModal(true);
  }

  async function doSelectImage(image: Image) {
    setSelectedImage(undefined);
    setShowImageModal(true);
    if (image.url === undefined) {
      try {
        const response = await api.getImageUrl(image.bucket, image.path);
        image.url = response.url;
        setSelectedImage(image);
      } catch (error) {
        console.error('Error occurred getting signed url', error);
        setError((error as Error).message ?? 'An unexpected error has occurred');
        setShowError(true);
      }
    } else {
      setSelectedImage(image);
    }
  }

  function doConfirmDeleteEditIssue() {
    setShowDeleteItemConfirmation(true);
  }

  /**
   * Marks a single issue as deleted.
   */
  async function doDeleteEditIssue() {
    setShowDeleteItemConfirmation(false);
    setShowEditModal(false);
    try {
      const update: UpdateIssuesStatus = {
        ids: [editIssue.id],
        value: 'deleted',
      };
      await api.updateIssuesStatus(update);
      await refresh();
    } catch (error) {
      setError((error as Error).message ?? 'An unexpected error has occurred');
      setShowError(true);
    }
  }

  /**
   * Updates an individual Issue.
   * @param issue The new values for the issue.
   */
  async function doUpdateEditIssue(issue: Issue) {
    setShowEditModal(false);
    try {
      const update: UpdateIssue = {
        id: issue.id,
        type: issue.type,
        workOrder: issue.workOrder,
        summary: issue.summary,
        severity: issue.severity,
      };
      await api.updateIssue(update);
      await refresh();
    } catch (error) {
      setError((error as Error).message ?? 'An unexpected error has occurred');
      setShowError(true);
    }
  }

  function doEditSelectedIssues(operation: 'approved' | 'unapproved' | 'deleted') {
    setSelectedOperation(operation);
    if (operation === 'approved') {
      setConfirmInfo({ title: 'Confirm Approval', message: `You have selected ${selected.size} issues. Are you sure you want to approve these records.` });
    } else if (operation === 'unapproved') {
      setConfirmInfo({ title: 'Confirm Unapproval', message: `You have selected ${selected.size} issues. Are you sure you want to unapprove these records.` });
    } else if (operation === 'deleted') {
      setConfirmInfo({ title: 'Confirm Deletion', message: `You have selected ${selected.size} issues. Are you sure you want to delete these records.` });
    }
    setShowConfirmIssuesDialog(true);
  }

  async function doConfirmIssues() {
    setShowConfirmIssuesDialog(false);
    setShowIssuesEditDialog(false);
    const update: UpdateIssuesStatus = {
      ids: [],
      value: selectedOperation,
    };
    selected.forEach((issue) => {
      update.ids.push(issue.id);
    });
    try {
      await api.updateIssuesStatus(update);
      refresh();
    } catch (error) {
      setError((error as Error).message ?? 'An unexpected error has occurred');
      setShowError(true);
    }
  }

  async function getObservation() {
    if (observationId === undefined) return;
    if (loadedObservationId !== observationId) {
      const observation = await api.getObservation(observationId);
      setData(observation);
      setLoadedObservationId(observationId);
    }
  }

  useEffect(() => {
    getProjects();
    setIsAdmin(hasAccessToAdmin());
    getObservation();
  });

  return (
    <>
      {isAdmin !== true && (
        <Container as="main" className="App py-4 px-3 mx-auto">
          <AuthHeader />
          <Container>
            <h1>Oops!</h1>
            <p>Sorry, an unexpected error has occurred.</p>
          </Container>
          <Footer />
        </Container>
      )}
      {isAdmin === true && (
        <Container as="main" className="App py-4 px-3 mx-auto" fluid>
          <AuthHeader />
          <Row>
            <Col lg={2}></Col>
            <Col>
              <Breadcrumb>
                <Breadcrumb.Item href="/admin">Admin</Breadcrumb.Item>
                <Breadcrumb.Item href={`/admin/observations/${projectId}`}>{project?.name} Observations</Breadcrumb.Item>
                <Breadcrumb.Item href={`/admin/observations/${projectId}/id/${observationId}`}>{observationId}</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
          <Row>
            <Col lg={2}>
              <LeftAdminNav projects={projects} onChange={onProjectChange} collapse={false} projectId={project?.id} />
            </Col>
            <Col lg={10}>
              <h2>Observation {observationId}</h2>
              {data !== null && (
                <>
                  <div className="row" style={{ paddingBottom: '10px' }}>
                    <div className="col-sm-1">
                      <button type="button" className="btn btn-primary rounded-pill" aria-label="Refresh" data-bs-toggle="refresh" onClick={() => refresh()}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-arrow-clockwise"
                          viewBox="0 0 16 16"
                        >
                          <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"></path>
                          <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"></path>
                        </svg>
                      </button>
                    </div>
                    <div className="col">
                      <Button
                        variant="primary"
                        type="button"
                        className="rounded-pill"
                        disabled={isButtonsDisabled()}
                        onClick={() => setShowIssuesEditDialog(true)}
                        style={{ marginRight: '10px' }}
                      >
                        edit
                      </Button>
                      <Button
                        variant="primary"
                        type="button"
                        className="rounded-pill"
                        disabled={isButtonsDisabled()}
                        onClick={() => doEditSelectedIssues('deleted')}
                      >
                        delete
                      </Button>
                    </div>
                  </div>
                  <Table striped bordered hover responsive>
                    <thead>
                      <tr>
                        <th>
                          <Form.Check ref={checkboxRef} key={'checkbox'} onChange={handleAllChecked} checked={isChecked()} />
                        </th>
                        <th>ID</th>
                        <th>Created</th>
                        <th>Type</th>
                        <th>Severity</th>
                        <th>Summary</th>
                        <th>Work Order</th>
                        <th>Images</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((issue) => (
                        <tr key={`issue-${issue.id}`}>
                          <td>
                            <Form.Check label={''} onChange={(event) => handleChecked(event, issue)} checked={selected.has(issue.id)} />
                          </td>
                          <td>
                            <button className="btn btn-link btn-no-shadow" onClick={() => doEditIssue(issue)}>
                              {issue.id}
                            </button>
                          </td>
                          <td>{`${new Date(issue.creationDate).toLocaleDateString()}  ${new Date(issue.creationDate).toLocaleTimeString()}`}</td>
                          <td>{issue.type}</td>
                          <td>{issue.severity}</td>
                          <td>{issue.summary}</td>
                          <td>{issue.workOrder}</td>
                          <td>
                            {issue.images.map((image, index) => (
                              <button className="btn btn-link btn-no-shadow" key={image.id} onClick={() => doSelectImage(image)}>
                                img{index}
                              </button>
                            ))}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {data === null && (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  )}
                </>
              )}
            </Col>
          </Row>

          <Footer />
          <IssueEditForm
            show={showEditModal}
            issue={editIssue}
            onHide={() => setShowEditModal(false)}
            onUpdate={doUpdateEditIssue}
            onDelete={doConfirmDeleteEditIssue}
          />
          <IssueImageView
            show={showImageModal}
            image={selectedImage}
            onHide={() => setShowImageModal(false)}
            onDelete={async () => {
              setShowImageModal(false);
              if (selectedImage !== undefined) {
                console.log('Deleting Image id for Observation id', selectedImage?.id, selectedImage?.observationId);
                try {
                  await api.deleteImage(selectedImage.id, selectedImage.observationId);
                  refresh();
                } catch (error) {
                  setError((error as Error).message ?? 'An unexpected error has occurred');
                  setShowError(true);
                }
              }
            }}
          />
          <IssueConfirmDialog
            show={showDeleteItemConfirmation}
            title="Confirm Deletion"
            message="Are you sure you want to delete this record."
            confirm={doDeleteEditIssue}
            onHide={() => setShowDeleteItemConfirmation(false)}
          />
          <IssuesEditDialog
            show={showIssuesEditDialog}
            onApprove={() => doEditSelectedIssues('approved')}
            onUnApprove={() => doEditSelectedIssues('unapproved')}
            onHide={() => setShowIssuesEditDialog(false)}
          />
          <IssueConfirmDialog
            show={showConfirmIssuesDialog}
            title={confirmInfo.title}
            message={confirmInfo.message}
            confirmName="Confirm"
            confirm={doConfirmIssues}
            onHide={() => setShowConfirmIssuesDialog(false)}
          />
          <ErrorDialog show={showError} title="Error Occurred" message={error ?? ''} onHide={() => setShowError(false)} />
        </Container>
      )}
    </>
  );
}

export default Observation;
