import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import { Camera } from '../../types/camera';

export interface CameraProps {
  camera: Camera;
}

export default function CameraView({ camera }: CameraProps) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function isOnline(): boolean {
    if (camera.captured === undefined) {
      return false;
    }
    const now = new Date();
    const timestamp = now.getTime() / 1000;
    return timestamp - camera.captured < 3600;
  }

  return (
    <>
      <Card id={camera.id} style={{ maxWidth: '225px', marginRight: '5px', marginBottom: '5px' }}>
        {camera.url !== undefined && <Card.Img variant="top" src={camera.url} alt={camera.name}></Card.Img>}
        {camera.url === undefined && (
          <div className="card-img-top text-center" style={{ width: '100%', height: '167.36px', backgroundColor: '#868e96' }}>
            <rect width="223" height="167.36px" fill="#868e96"></rect>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="white"
              className="bi bi-image"
              viewBox="0 0 16 16"
              style={{ marginTop: '35%' }}
            >
              <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
              <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1z" />
            </svg>
          </div>
        )}
        <Card.Body>
          <Card.Title>{camera.deviceName}</Card.Title>
          <div>{camera.name}</div>
          <div style={{ display: 'inline-block', fontWeight: 'bold' }}>Status:</div>
          <div className="online" hidden={!isOnline()}></div>
          <div className="offline" hidden={isOnline()}></div>
          <Card.Text>
            <b>Captured:</b> {camera.captured === undefined ? 'N/A' : new Date(camera.captured * 1000).toLocaleString()}
          </Card.Text>
          <Button variant="primary" className="rounded-pill" style={{ width: '140px' }} onClick={handleShow} disabled={camera.url === undefined}>
            full screen
          </Button>
        </Card.Body>
      </Card>
      <Modal id={`${camera.id}Modal`} show={show} onHide={handleClose} fullscreen={true}>
        <Modal.Header closeButton>
          <Modal.Title>
            {camera.deviceName}: {camera.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <img src={camera.url} alt={camera.name} className="img-thumbnail" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
