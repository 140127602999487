import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import * as EmailValidator from 'email-validator';
import Header from '../components/header';
import Footer from '../components/footer';
import { useValidPassword, useValidUsername } from '../hooks/auth-hooks';
import { SessionContext } from '../context/session-context';
import logo from '../assets/onsight-login.png';

export default function ForgotPasswordForm() {
  const { username, setUsername, usernameIsValid } = useValidUsername('');
  const { password, setPassword, passwordIsValid } = useValidPassword('');
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [codeRequired, setCodeRequired] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isValid = !usernameIsValid || username.length === 0 || !passwordIsValid || password.length === 0;

  const history = useNavigate();

  const sessionContext = useContext(SessionContext);

  const submitCodeForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!EmailValidator.validate(username)) {
      setError('Please enter a valid email address');
      return;
    }
    try {
      await sessionContext.sendCode(username);
      setCodeRequired(true);
    } catch (error) {
      console.error('Error', error);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setError((error as any).message);
    }
  };

  const submitForgotForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await sessionContext.forgotPassword(username, code, password);
      history('/');
    } catch (error) {
      console.error('Error', error);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setError((error as any).message);
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setUsername(value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPassword(value);
  };

  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setCode(value);
  };

  return (
    <>
      <Container as="main" className="py-4 px-3 mx-auto">
        <Header />
        <Container style={{ width: '350px' }} className="align-items-center">
          <a href="/" className="d-inline-flex link-body-emphasis text-decoration-none" style={{ paddingBottom: '20px' }}>
            <img src={logo} alt="Logo" width="350px" />
          </a>

          {codeRequired === false && (
            <>
              <Form onSubmit={submitCodeForm}>
                <p className="test-center">
                  Enter the email address associated with your account and a verification code will be sent to enable you to reset your password.
                </p>
                <Form.Group className="mb-4" controlId="codeGroup">
                  <Form.Label>email</Form.Label>
                  <Form.Control className="rounded-pill" type="email" value={username} onChange={handleEmailChange} required={true} />
                </Form.Group>
                <Alert variant="danger" hidden={error === ''}>
                  {error}
                </Alert>
                <div className="text-center">
                  <Button variant="primary" type="submit" className="rounded-pill" style={{ width: '140px' }}>
                    Send code
                  </Button>
                </div>
              </Form>
            </>
          )}
          {codeRequired === true && (
            <>
              <Form onSubmit={submitForgotForm}>
                <p className="text-center">A verification code has been emailed to you.</p>
                <Form.Group className="mb-4" controlId="emailGroup">
                  <Form.Label>username</Form.Label>
                  <Form.Control className="rounded-pill" type="email" value={username} onChange={handleEmailChange} required={true} />
                </Form.Group>
                <Form.Group className="mb-4" controlId="codeGroup">
                  <Form.Label>code</Form.Label>
                  <Form.Control className="rounded-pill" type="text" value={code} onChange={handleCodeChange} required={true} />
                </Form.Group>
                <Form.Group className="mb-4" controlId="passwordGroup">
                  <Form.Label>new password</Form.Label>
                  <Form.Control className="rounded-pill" type="password" value={password} onChange={handlePasswordChange} required={true} />
                </Form.Group>
                <Alert variant="danger" hidden={error === ''}>
                  {error}
                </Alert>
                <div className="text-center">
                  <Button variant="primary" type="submit" className="rounded-pill">
                    Update password
                  </Button>
                </div>
              </Form>
            </>
          )}
        </Container>
        <Footer />
      </Container>
    </>
  );
}
