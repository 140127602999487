import { useEffect, useState, useContext } from 'react';
import ReactPlayer from 'react-player';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AuthHeader from '../components/site-header';
import Footer from '../components/footer';
import { Device } from '../types/device';
import LeftNav from '../components/left-nav';
import { DeviceContext, IDeviceContext } from '../context/device-context';
import AWS from 'aws-sdk';
import { Camera } from '../types/camera';

export default function LiveVideo() {
  let list = new Array<string>();
  const [connections, setConnections] = useState<Array<any>>([]);
  const [messages, setMessages] = useState<Array<string>>([]);
  const deviceContext = useContext(DeviceContext);
  const [devices, setDevices] = useState<Array<Device> | null>(null);
  const [cameras, setCameras] = useState<Array<Camera>>([]);
  const protocol = 'HLS';
  const streamName = 'test-video-stream';
  let kinesisVideo: any = null;
  let kinesisVideoArchivedContent: any = null;

  const logMessage = async (message: string) => {
    list = [...list, message];
    setMessages(list);
  };

  function setupCameras(context: IDeviceContext) {
    const devices = context.devices;
    const cams: Array<Camera> = [];
    devices.forEach((device) => {
      device.cameras?.forEach((camera) => {
        cams.push(camera);
      });
    });
    setDevices(devices);
    setCameras(cams);
  }

  const getEndpoint = () => {
    if (kinesisVideo === null) {
      console.log('Kinesis video not setup');
      logMessage('Kinesis video not setup');
      return;
    }
    logMessage('Getting Data Endpoint');
    kinesisVideo.getDataEndpoint(
      {
        StreamName: streamName,
        APIName: 'GET_HLS_STREAMING_SESSION_URL',
      },
      function (err: any, response: any) {
        if (err) {
          return console.error(err);
        }
        logMessage('Data endpoint: ' + response.DataEndpoint);
        console.log('Data endpoint: ' + response.DataEndpoint);
        kinesisVideoArchivedContent.endpoint = new AWS.Endpoint(response.DataEndpoint);
      }
    );
  };

  const getStream = () => {
    if (kinesisVideoArchivedContent === null) {
      console.log('Kinesis video not setup');
      logMessage('Kinesis video not setup');
      return;
    }
    logMessage('Fetching HLS Streaming Session URL');
    console.log('Fetching HLS Streaming Session URL');
    const playbackMode = 'LIVE'; // 'LIVE' or 'ON_DEMAND'
    //var startTimestamp = new Date('START_TIMESTAMP'); // For ON_DEMAND only
    //var endTimestamp = new Date('END_TIMESTAMP'); // For ON_DEMAND only
    const fragmentSelectorType = 'SERVER_TIMESTAMP'; // 'SERVER_TIMESTAMP' or 'PRODUCER_TIMESTAMP'
    const SESSION_EXPIRATION_SECONDS = `${60 * 60}`;
    console.log(kinesisVideo);
    const hlsUrl = kinesisVideoArchivedContent.getHLSStreamingSessionURL(
      {
        StreamName: streamName,
        //StreamARN: "arn:aws:kinesisvideo:us-east-1:635420739373:stream/mr-pinchers-dot-org/1561848963391",
        PlaybackMode: playbackMode,
        HLSFragmentSelector: {
          FragmentSelectorType: fragmentSelectorType,
          TimestampRange:
            playbackMode === 'LIVE'
              ? undefined
              : {
                  //            StartTimestamp: startTimestamp,
                  //            EndTimestamp: endTimestamp
                },
        },
        Expires: parseInt(SESSION_EXPIRATION_SECONDS),
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      function (err: unknown, response: any) {
        if (err) {
          logMessage(JSON.stringify(err as Error));
          return console.error('Darn', err);
        }
        console.log('HLS Streaming Session URL: ' + response.HLSStreamingSessionURL, response);
        setConnections((prevState) => [...prevState, response.HLSStreamingSessionURL]);
        console.log('connections:', connections);
      }
    );
  };

  const startPlayback = () => {
    if (kinesisVideo === null) {
      logMessage('Initializing');
      // Step 1: Configure SDK Clients
      const options = {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        sessionToken: undefined,
        region: 'us-east-1',
        endpoint: undefined,
      };
      console.log('Creds', { id: process.env.REACT_APP_AWS_ACCESS_KEY_ID, key: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY });
      kinesisVideo = new AWS.KinesisVideo(options);
      kinesisVideoArchivedContent = new AWS.KinesisVideoArchivedMedia(options);
    }
    getEndpoint();

    setTimeout(() => {
      getStream();
    }, 5000);
  };

  useEffect(() => {
    try {
      setupCameras(deviceContext);
    } catch (error) {
      console.error('Error occurred getting location', error);
    }
  }, [deviceContext]);

  const handleChange = (_event: any) => {
    logMessage(JSON.stringify(_event.target.value));
    console.log(_event);
  };

  return (
    <Container as="main" className="App py-4 px-3 mx-auto" fluid>
      <AuthHeader />
      <Row>
        <Col lg={2}>{devices !== null && <LeftNav devices={devices} />}</Col>
        <Col lg={10}>
          <h3>Live Video</h3>
          <div id="video-select" className="row">
            <div className="col">
              <select className={'form-select'} value={`${cameras.at(0)?.deviceName} - ${cameras.at(0)?.name}`} onChange={handleChange}>
                {cameras.map((camera) => (
                  <option id={`camera-${camera.id}`} key={camera.id} value={`${camera.deviceName} - ${camera.name}`}>
                    {`${camera.deviceName} - ${camera.name}`}
                  </option>
                ))}
              </select>
            </div>
            <div className="col">
              <button id="start" type="button" className="btn btn-primary" onClick={startPlayback}>
                Start Playback
              </button>
            </div>
          </div>
          <div id="playerContainer">
            {connections.length > 0 && <ReactPlayer height={'auto'} width={'100%'} controls={true} url={connections[0]} playing={true} />}
          </div>
          <h3 style={{ marginTop: '20px' }}>Logs</h3>
          <div className="card bg-light mb-3">
            <pre id="logs" className="card-body text-monospace" style={{ fontFamily: 'monospace', whiteSpace: 'pre-wrap' }}>
              {messages.map((log) => {
                return `${log}\n`;
              })}
            </pre>
          </div>
        </Col>
      </Row>

      <Footer />
    </Container>
  );
}
