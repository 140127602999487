import Button from 'react-bootstrap/Button';
import Modal, { ModalProps } from 'react-bootstrap/Modal';

export interface IssueEditFormProps extends ModalProps {
  message: string;
  title: string;
  cancelName?: string;
  confirmName?: string;
  confirm: () => void;
}

function IssueConfirmDialog(props: IssueEditFormProps) {
  const modelProps: ModalProps = { ...props };
  delete modelProps.confirm;
  delete modelProps.confirmName;
  delete modelProps.message;
  delete modelProps.title;
  return (
    <Modal {...modelProps} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{props.message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={props.onHide}>
          Cancel
        </Button>
        <Button variant="danger" onClick={props.confirm}>
          {props.confirmName === undefined ? 'Delete' : props.confirmName}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default IssueConfirmDialog;
