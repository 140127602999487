import React, { useState, useEffect, useContext } from 'react';
import { CognitoUser, CognitoUserAttribute, CognitoUserSession, ICognitoUserAttributeData } from 'amazon-cognito-identity-js';
import { CognitoService } from '../service/cognito-service';

const userPoolId = process.env.REACT_APP_USERPOOL_ID ?? '';
const clientId = process.env.REACT_APP_CLIENT_ID ?? '';

export enum SessionStatus {
  Loading,
  Enabled,
  Disabled,
}

export interface SessionDetails {
  username?: string;
  email?: string;
  site?: string;
  sub?: string;
  isAdmin?: string;
  accessToken?: string;
  refreshToken?: string;
  token?: string;
}

export interface Session {
  details: SessionDetails;
  status: SessionStatus;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attrInfo: Record<string, any>;
  login(email: string, password: string): Promise<void>;
  logout(): Promise<void>;
  verifyCode(username: string, code: string): Promise<void>;
  sendCode(username: string): Promise<void>;
  getSession(): Promise<CognitoUserSession | null>;
  getAttributes(): Promise<CognitoUserAttribute[] | undefined>;
  setAttribute(attr: ICognitoUserAttributeData): Promise<void>;
  forgotPassword(username: string, code: string, password: string): Promise<void>;
  changePassword(oldPassword: string, newPassword: string): Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  completeNewPasswordChallenge(user: CognitoUser, userAttributes: any, newPassword: string): Promise<void>;
}

const initialContext: Session = {
  details: {},
  status: SessionStatus.Loading,
  attrInfo: {},
  login: function (email: string, password: string): Promise<void> {
    throw new Error('Function not implemented.');
  },
  logout: function (): Promise<void> {
    throw new Error('Function not implemented.');
  },
  verifyCode: function (username: string, code: string): Promise<void> {
    throw new Error('Function not implemented.');
  },
  sendCode: function (username: string): Promise<void> {
    throw new Error('Function not implemented.');
  },
  getSession: function (): Promise<CognitoUserSession | null> {
    throw new Error('Function not implemented.');
  },
  getAttributes: function (): Promise<CognitoUserAttribute[] | undefined> {
    throw new Error('Function not implemented.');
  },
  setAttribute: function (attr: ICognitoUserAttributeData): Promise<void> {
    throw new Error('Function not implemented.');
  },
  forgotPassword: function (username: string, code: string, password: string): Promise<void> {
    throw new Error('Function not implemented.');
  },
  changePassword: function (oldPassword: string, newPassword: string): Promise<void> {
    throw new Error('Function not implemented.');
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  completeNewPasswordChallenge: function (user: CognitoUser, userAttributes: any, newPassword: string): Promise<void> {
    throw new Error('Function not implemented.');
  },
};

type Props = {
  children?: React.ReactNode;
};

export const SessionContext = React.createContext(initialContext);
const cognitoApi = new CognitoService(userPoolId, clientId);

export const UserIsLoggedIn = ({ children }: Props) => {
  const { status }: Session = useContext(SessionContext);
  return <>{status === SessionStatus.Enabled ? children : null}</>;
};

export const UserIsLoggedOut = ({ children }: Props) => {
  const { status }: Session = useContext(SessionContext);
  return <>{status === SessionStatus.Disabled ? children : null}</>;
};

const SessionProvider = ({ children }: Props) => {
  const [sessionStatus, setSessionStatus] = useState<SessionStatus>(SessionStatus.Loading);
  const [details, setDetails] = useState<SessionDetails>({});
  const [attrInfo, setAttrInfo] = useState<Array<CognitoUserAttribute>>([]);

  useEffect(() => {
    async function getSessionInfo() {
      try {
        const session = await getSession();
        window.localStorage.setItem('accessToken', `${session?.getAccessToken().getJwtToken()}`);
        window.localStorage.setItem('refreshToken', `${session?.getRefreshToken().getToken()}`);
        window.localStorage.setItem('token', `${session?.getIdToken().getJwtToken()}`);
        const attrs = await getAttributes();
        setAttrInfo(attrs ?? []);
        const details: SessionDetails = {
          accessToken: session?.getAccessToken().getJwtToken(),
          refreshToken: session?.getRefreshToken().getToken(),
          token: session?.getIdToken().getJwtToken(),
        };
        attrs?.forEach((attr) => {
          switch (attr.Name) {
            case 'email':
              details.email = attr.Value;
              break;
            case 'sub':
              details.sub = attr.Value;
              break;
            case 'custom:siteId':
              details.site = attr.Value;
              break;
            case 'custom:isAdmin':
              details.isAdmin = attr.Value;
              break;
            default:
          }
        });
        setDetails(details);
        setSessionStatus(SessionStatus.Enabled);
      } catch (err) {
        setSessionStatus(SessionStatus.Disabled);
      }
    }
    getSessionInfo();
  }, [setSessionStatus, sessionStatus]);

  if (sessionStatus === SessionStatus.Loading) {
    return null;
  }

  async function login(email: string, password: string) {
    try {
      await cognitoApi.login(email, password);
      setSessionStatus(SessionStatus.Enabled);
    } catch (err) {
      setSessionStatus(SessionStatus.Disabled);
      throw err;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function completeNewPasswordChallenge(user: CognitoUser, requiredAttributes: any, newPassword: string): Promise<void> {
    return new Promise(function (resolve, reject) {
      user.completeNewPasswordChallenge(newPassword, requiredAttributes, {
        onSuccess: function (res: CognitoUserSession) {
          setSessionStatus(SessionStatus.Enabled);
          resolve();
        },
        onFailure: function (err: Error) {
          setSessionStatus(SessionStatus.Disabled);
          reject(err);
        },
      });
    });
  }

  async function logout() {
    setSessionStatus(SessionStatus.Disabled);
    return cognitoApi.logout();
  }

  async function verifyCode(username: string, code: string) {
    return cognitoApi.verifyCode(username, code);
  }

  async function getSession(): Promise<CognitoUserSession | null> {
    return cognitoApi.getSession();
  }

  async function getAttributes(): Promise<CognitoUserAttribute[] | undefined> {
    return cognitoApi.getAttributes();
  }

  async function setAttribute(attr: ICognitoUserAttributeData): Promise<void> {
    return cognitoApi.setAttribute(attr);
  }

  async function sendCode(username: string): Promise<void> {
    return cognitoApi.sendCode(username);
  }

  async function forgotPassword(username: string, code: string, password: string): Promise<void> {
    await cognitoApi.forgotPassword(username, code, password);
  }

  async function changePassword(oldPassword: string, newPassword: string): Promise<void> {
    await cognitoApi.changePassword(oldPassword, newPassword);
  }

  const state: Session = {
    status: sessionStatus,
    details,
    attrInfo,
    login,
    logout,
    verifyCode,
    getSession,
    sendCode,
    forgotPassword,
    changePassword,
    getAttributes,
    setAttribute,
    completeNewPasswordChallenge,
  };

  return <SessionContext.Provider value={state}>{children}</SessionContext.Provider>;
};

export default SessionProvider;
