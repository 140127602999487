import logo from '../assets/onsight-login.png';
import Container from 'react-bootstrap/Container';
import { SessionContext } from '../context/session-context';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthHeader: React.FC = () => {
  const sessionContext = useContext(SessionContext);
  const history = useNavigate();
  const logout = async () => {
    sessionContext.logout();
  };
  const navigateToSub = () => history('/subscribe');

  function isAdmin() {
    const email = sessionContext.details.email;
    return (email !== undefined && email.endsWith('onsightops.com')) || sessionContext.details.isAdmin === 'true';
  }

  return (
    <Container>
      <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 border-bottom">
        <a href="/" className="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-white text-decoration-none">
          <img src={logo} alt="Logo" width="150px" />
        </a>

        <ul className="nav col-12 col-lg-auto my-2 justify-content-center my-md-0">
          {/* <li>
            <a href="#locations" className="nav-link px-2">
              Locations
            </a>
          </li> */}
          {isAdmin() && (
            <li>
              <a href="/admin" className="nav-link px-2">
                Admin
              </a>
            </li>
          )}

          <li>
            <a href="/" className="nav-link px-2">
              Live View
            </a>
          </li>
          {/* <li>
            <a href="#alerts" className="nav-link px-2">
              Alerts
            </a>
          </li> */}
        </ul>
        <form className="d-flex">
          <button onClick={navigateToSub} type="button" className="btn btn-primary rounded-circle">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bell-fill" viewBox="0 0 16 16">
              <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z"></path>
            </svg>
          </button>
          <button onClick={logout} type="button" className="btn btn-primary rounded-circle">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-power" viewBox="0 0 16 16">
              <path d="M7.5 1v7h1V1z" />
              <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812" />
            </svg>
          </button>
        </form>
      </header>
    </Container>
  );
};

export default AuthHeader;
