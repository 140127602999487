import Button from 'react-bootstrap/Button';
import Modal, { ModalProps } from 'react-bootstrap/Modal';

export interface IssuesEditDialogProps extends ModalProps {
  onApprove: () => void;
  onUnApprove: () => void;
}

function IssuesEditDialog(props: IssuesEditDialogProps) {
  const modelProps: ModalProps = { ...props };
  delete modelProps.onApprove;
  delete modelProps.onUnApprove;
  return (
    <Modal {...modelProps} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Edit Selected Issues</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          You can approve or unapprove all selected issues, you will be presented with a dialog to confirm your selection and the number of records affected.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => props.onApprove()}>Approve Selected</Button>
        <Button onClick={() => props.onUnApprove()}>Unapprove Selected</Button>
        <Button variant="primary" onClick={props.onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default IssuesEditDialog;
