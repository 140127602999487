import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './index.css';
import ErrorPage from './ErrorPage';
import reportWebVitals from './reportWebVitals';
// import NoAuthDashboard from './routes/noauth-dashboard';
// import Original from './routes/original';
import Login from './routes/login';
import SessionProvider, { UserIsLoggedIn, UserIsLoggedOut } from './context/session-context';
import Dashboard from './routes/dashboard';
import ChangePasswordForm from './routes/auth/change-password';
import Overview from './routes/overview';
import Alerts from './routes/alerts';
import LiveView from './routes/liveview';
import DeviceProvider from './context/device-context';
import Subscribe from './routes/subscribe';
import LiveVideo from './routes/live-video';
import ForgotPasswordForm from './routes/forgot';
import AdminDashboard from './routes/admin-dashboard';
import Observations from './routes/observations';
import Observation from './routes/observation';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const MainRoute: React.FunctionComponent = () => (
  <BrowserRouter>
    <Routes>
      <Route path="*" element={<ErrorPage />} />
      <Route path="/" Component={Dashboard} />
      <Route path="/admin" Component={AdminDashboard} />
      <Route path="/admin/observations/:projectId" Component={Observations} />
      <Route path="/admin/observations/:projectId/id/:observationId" Component={Observation} />
      <Route path="/live-video" Component={LiveVideo} />
      <Route path="/overview/:robot" Component={Overview} />
      <Route path="/liveview/:robot" Component={LiveView} />
      <Route path="/alerts/:robot" Component={Alerts} />
      <Route path="/subscribe" Component={Subscribe} />
    </Routes>
  </BrowserRouter>
);

const LoginRoute: React.FunctionComponent = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" Component={Login} />
      <Route path="/forgot" Component={ForgotPasswordForm} />
      <Route path="/changePassword" Component={ChangePasswordForm} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  </BrowserRouter>
);

const MyApp: React.FunctionComponent = () => (
  <SessionProvider>
    <UserIsLoggedIn>
      <DeviceProvider>
        <MainRoute />
      </DeviceProvider>
    </UserIsLoggedIn>
    <UserIsLoggedOut>
      <LoginRoute />
    </UserIsLoggedOut>
  </SessionProvider>
);

root.render(
  <React.StrictMode>
    <MyApp />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
