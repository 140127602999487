import { useContext, useEffect, useState } from 'react';
import '../App.css';
import AuthHeader from '../components/site-header';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Footer from '../components/footer';
import CameraLoader from '../components/camera/camera-loader';
import { Camera } from '../types/camera';
import { Site } from '../types/site';
import { Device } from '../types/device';
import LeftNav from '../components/left-nav';
import { Api } from '../services/api';
import { DeviceContext, IDeviceContext } from '../context/device-context';
import DevicesView from '../components/devices-view';

function Dashboard() {
  const api = Api.getInstance();
  const [devices, setDevices] = useState<Array<Device> | null>(null);
  const [timr, setTimr] = useState<NodeJS.Timer | null>(null);
  const [cameras, setCameras] = useState<Array<Camera> | null>(() => {
    const siteString = window.sessionStorage.getItem('site');
    if (siteString !== null) {
      const site = JSON.parse(siteString) as Site;
      let cameras: Array<Camera> = [];
      const keys = Object.keys(site.devices);
      const devices: Array<Device> = [];
      keys.forEach((id) => {
        devices.push(site.devices[id]);
        const deviceCameras = site.devices[id].cameras;
        if (deviceCameras !== undefined) {
          cameras = cameras.concat(deviceCameras);
        }
      });
      devices.sort((a, b) => (a.deviceName?.toLowerCase() > b.deviceName?.toLowerCase() ? 1 : -1));
      setDevices(devices);
      return cameras;
    }
    return null;
  });
  const deviceContext = useContext(DeviceContext);

  async function getCameraFrameApi(camera: Camera) {
    if (camera.url === undefined) return;
    try {
      const cameraFrame = await api.getCameraFrame(camera);
      const updatedCameras = cameras?.map((camera) => {
        if (camera.name === cameraFrame.name && camera.device === cameraFrame.device) {
          return {
            name: cameraFrame.name,
            id: cameraFrame.id,
            url: cameraFrame.url,
            captured: cameraFrame.captured,
            device: cameraFrame.device,
            deviceName: cameraFrame.deviceName,
          };
        } else {
          return camera;
        }
      });
      if (updatedCameras !== undefined) {
        setCameras(updatedCameras);
        const deviceState = deviceContext.getDevice(cameraFrame.device);
        if (deviceState !== undefined) {
          deviceState.cameras = updatedCameras?.filter((camera) => camera.device === cameraFrame.device);
        }
      }
    } catch (error) {
      console.error('Error occurred fetching camera frame', error);
    }
  }

  async function setupCameras(context: IDeviceContext) {
    try {
      const cameras: Array<Camera> = [];
      const devices = context.devices;
      devices.forEach((device) => {
        if (device.cameras !== undefined) {
          cameras.push(...device.cameras);
        }
      });
      devices.sort((a, b) => (a.deviceName.toLowerCase() > b.deviceName.toLowerCase() ? 1 : -1));
      setCameras(cameras);
      setDevices(devices);
    } catch (error) {
      console.error('Error occurred getting site data', error);
    }
  }

  useEffect(() => {
    setupCameras(deviceContext);
    if (timr !== null) {
      clearInterval(timr);
      setTimr(null);
    }
    setTimr(
      setInterval(() => {
        cameras?.forEach((camera) => {
          getCameraFrameApi(camera);
        });
      }, 60000)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceContext]);

  return (
    <>
      <Container as="main" className="App py-4 px-3 mx-auto" fluid>
        <AuthHeader />
        <Row>
          <Col lg={2}>{devices !== null && <LeftNav devices={devices} />}</Col>
          <Col lg={10}>
            {cameras === null && <CameraLoader></CameraLoader>}
            {cameras !== null && cameras.length > 0 && <DevicesView devices={devices || []}></DevicesView>}
          </Col>
        </Row>

        <Footer />
      </Container>
    </>
  );
}

export default Dashboard;
